<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard">
        <div class="postercard__img">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.6288313881637!2d85.32679331445289!3d27.666953133874173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19dc61aff749%3A0xd8643efebc97aca4!2sWishers%20Tech%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1633825549283!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
    <div class="breadcrumb">
        <div class="container">
            <ul>
                <li><router-link :to="{name:'Home'}">Home</router-link></li>
                <li><span class="live">Tailormade {{$route.params.title}}</span></li>
            </ul>
        </div>
    </div>
    <section class="contact">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-7 md-7">
                    <div class="contact__box">
                        <h1 class="folio__title">Enquiry for Tailormade {{$route.params.title}}</h1>
                        <p>You can fill up the form with any query related to the package and our Customer Support will respond you as soon as possible.</p>
                        <ul>
                        <li v-for="item in error" v-bind:key="item">
                        {{item}} not valid
                        </li>
                        </ul>
                        <form @submit.prevent="onSubmit" class="general-form contact__box-form">
                            <div class="form-module form-lblstyle">
                                <label for="ctName" class="form-label">Your Name</label>
                                <div class="form-set">
                                    <input type="text" autocomplete="off" v-model="form.Name" id="ctName" class="form-input">
                                </div>
                            </div>
                            <div class="form-module form-lblstyle">
                                <label for="ctEmail" class="form-label">Email Address</label>
                                <div class="form-set">
                                    <input type="email" autocomplete="off" v-model="form.Email" id="ctEmail" class="form-input">
                                </div>
                            </div>
                            <div class="form-module form-lblstyle">
                                <label for="ctNumber" class="form-label">Contact Number</label>
                                <div class="form-set">
                                    <input type="tel" autocomplete="off" v-model="form.Number" id="ctNumber" class="form-input">
                                </div>
                            </div>
                            <div class="form-module form-lblstyle">
                                <label for="ctSubject" class="form-label">Subject</label>
                                <div class="form-set">
                                    <input type="text" autocomplete="off" v-model="form.Subject" id="ctSubject" name="ctSubject" class="form-input">
                                </div>
                            </div>
                            <div class="form-module form-lblstyle">
                                <label for="ctMessage" class="form-label">Send us your message/query/feedback</label>
                                <div class="form-set">
                                    <textarea cols="30" rows="10" v-model="form.Message" id="ctMessage" autocomplete="off" class="form-input"></textarea>
                                </div>
                            </div>
                            <div class="form-action">
                                <input type="submit" @click="sendMessage" value="Send Message" class="btn btn-secondary">
                            </div>
                        </form>

                    </div>
                </div>
                <div class="col xs-12 sm-5 md-5">
                    <div class="contact__address" v-for="content in contactblock" :key="content.id">
                        <img :src="urlPath+'uploads/media/page/'+content.image" alt="content.title">
						<p v-html="content.description"></p>
						
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import jQuery from 'jquery';
// import "../assets/js/smtp.js";
export default ({
    data() {
        return {
            contactblock: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
            form:{
                Name:'',
                Email:'',
                Number:'',
                Subject:'',
                Message:'',
            },
            error:[],

        }
    },
    async mounted() {
        this.contactPage(this.$route.params.slug);
        if(this.$route.params.title){
            this.form.Subject = "Tailored Package Query for "+this.$route.params.title;
        }

		jQuery('.form-lblstyle .form-input').focusin(function() {
		jQuery(this).parents('.form-lblstyle').addClass('label-active');
	});

	jQuery('.form-lblstyle .form-input').focusout(function() {
		var lblStyle = jQuery(this).val();
		if (lblStyle == "") {
			jQuery(this).parents('.form-lblstyle').removeClass('label-active');
		}
		});
		
    },
    methods: {
        contactPage(slugurl) {
            const descEl = document.querySelector('head meta[name="description"]');
            const keyEl = document.querySelector('head meta[name="keywords"]');
            const titleEl = document.querySelector('head title');

            descEl.setAttribute('content', 'Tailored made package for ' + this.$route.params.title);
            keyEl.setAttribute('content', 'Tailored, Customized Package');
            titleEl.textContent = "Tailored Package for "+ this.$route.params.title + " :: Nepal Alternative Treks";
            },

        sendMessage(){
            this.error=[];
            for(const item in this.form){
                if(this.form[item]===''){
                    this.error.push(item);
                }
            }
            if(this.error.length===0){
                Email.send({
                    SecureToken: "9851e406-8a43-4d9e-9eac-db0237385302",
                    To : 'suruju.red@gmail.com',
                    From : "suruju.red@gmail.com",
                    Subject : this.form.Subject,
                    Body : '<b>From:</b> '+ this.form.Name +'<br/><b>Email:</b> '+this.form.Email +'<br/><b>Contact Number:</b> '+this.form.Number+'<br/><b>Subject:</b> '+this.form.Subject+'<br/><b>Message:</b> '+this.form.Message
                    }).then(
                    // message => alert(message)
                this.$router.push({name:'EnquiryConfirm', params: {fullname: this.form.Name}})
                );
            }
            // console.log(this.form,this.error);
        },
        },
    watch: {
        '$route.params.slug'(newSlug) {
            this.contactPage(newSlug);
        }
    },
    })
</script>
